import React from 'react';

const FAQ = () => {
    return (
        <div id='FAQ' className="bg-[#edeced] py-24 px-4 md:px-0 scroll-pt-6 scroll-mt-16">
            <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center text-center md:text-left">
                {/* Text Section */}
                <div className="space-y-8 text-[#342b29] flex flex-col justify-center items-center md:items-start">
                    <h2 className="text-4xl font-the-seasons">YOUR QUESTIONS, <br /> ANSWERED</h2>

                    <div className="font-tt-drugs">
                        <h3 className="text-lg font-semibold">WHICH MARKETS DO YOU SERVE?</h3>
                        <p className="">
                            We serve clients in the DC, Maryland, and Northern Virginia areas, offering our services for
                            parties, weddings, corporate events, graduations, or any celebration you have in mind.
                        </p>
                    </div>

                    <div className="font-tt-drugs">
                        <h3 className="text-lg font-semibold">SLOTS AVAILABLE WHEN BOOKING STARTS:</h3>
                        <p className="">
                            Right now, we open bookings for 3 weeks at a time, with a maximum of 6 events per period. This
                            ensures we can deliver the best experience without compromising on quality. As we grow, we
                            plan to increase the number of slots available.
                        </p>
                    </div>

                    <div className="font-tt-drugs">
                        <h3 className="text-lg font-semibold">WHAT HAPPENS AFTER I SIGN UP FOR THE WAITLIST?</h3>
                        <p className="">
                            You’ll be added to an exclusive list with priority to special deals and the earliest booking
                            dates. It also counts as your entry to the FREE picnic. Since spots are limited, we will make
                            sure you know first when we open.
                        </p>
                    </div>

                    <div className="font-tt-drugs">
                        <h3 className="text-lg font-semibold">HOW DO I SECURE A DATE?</h3>
                        <p className="">
                            Just drop us a message in the inquiry box below or hit us up on any of our socials. We love
                            hearing from you and can’t wait to help make your special date a reality!
                        </p>
                    </div>
                </div>
                {/* Image Section */}
                <div className="flex justify-center md:justify-start ml-0 md:ml-28">
                    <div className="relative">
                        <div className="absolute inset-0 bg-[#ddd6d3] rounded-[30px] md:rounded-[60px] shadow-lg transform translate-x-5 translate-y-5 md:translate-x-20 md:translate-y-16 hidden lg:block"></div>
                            <div className='overflow-hidden rounded-[60px]'>
                            <img
                                src="/assets/faq-two-knit-chairs-with-umbrella-between-grazed-by-the-golden-hour-sunlight-date-saver-co-picnic-DMV.webp"
                                alt="Outdoor scene"
                                className="relative rounded-[30px] md:rounded-[60px] shadow-lg w-full max-w-sm h-auto transition duration-300 ease-in-out transform hover:scale-110"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQ;
