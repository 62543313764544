import React from 'react';
import ServiceSection from './ServiceSection';

const Services = () => {
    const servicesData = [
        {
            title: 'FYP',
            imageUrl: '/assets/FYP-for-you-picnic-table-scape-sunflower-arrangement-date-saver-co-DMV.webp',
            description: "<span class='font-bold'>For You Picnic:</span> We deeply believe that disconnecting is where you can truly connect. The FYP was especially made for just that purpose. <br/><br/> <span class='font-bold'>Who is it for?</span> <br/>For couples, groups, and friends wanting to “disconnect to connect” out in nature.",
            whatsInItForYou: [
                'Saves time from researching and coordinating materials',
                'No hauling bags, tables around',
                'More enriching time with your loved ones',
                'No stress about perfect posts, it’s all share-worthy',
                'Quick way to impress in just a few clicks',
                'None to minimal decisions on your part',
                'On-demand support'
            ],
            whatsIncludedInPackages: [
                'Picnic Table and Table Scape',
                'Pillows and Water-Proof Mats',
                'Personalized Place Cards for Each Guest (Glass Arch Frames)',
                'NA Sparkling Wine and Water',
                'Seasonal Fresh Flowers',
                'Throw Blanket',
                'Boho Umbrella',
                'Knit Lounge Chairs',
                'Activities (Conversation Starter Cards, Painting Kits...)',
                'Arch selection',
                'Cabana, Boho Umbrella',
                'Teepee 7.8\' tall',
                'Electric Handheld Fans'
            ]
        },
        {
            title: 'Glam Photobooth',
            imageUrl: '/assets/glam-photobooth-beautiful-couple-taking-a-picture-old-hollywood-style-date-saver-co-DMV.webp',
            description: 'High Resolution Photos. Our spaces are designed for connection, not pictures for perfection. Do as many takes a you want. Have fun posing while you’re at it!</br></br><span class="font-bold">Who is it for?</span></br> For weddings, celebrants, and corporate teams who want to offer elegant photo favors without the fuss.',
            whatsInItForYou: [
                'Unique photobooth experience',
                'Instant printouts',
                'Custom backdrops',
                'High-quality images'
            ],
            whatsIncludedInPackages: [
                'Photobooth setup',
                'On-site technician',
                'Props and accessories',
                'Digital gallery'
            ]
        },
        {
            title: 'Lazy Boy',
            imageUrl: '/assets/lazy-boy-home-date-night-man-pours-sparkling-wine-into-flute-glass-date-saver-co-DMV.webp',
            description: 'You still want to do an intimate date night but picnics don’t quite suite you. We have got your back! We’ll take care of setting up a perfect date atmosphere while you relax and enjoy your evening. We’ll clean up the next day, keeping your special time together seamless. </br></br><span class="font-bold">Who is it for?</span></br>For groups or couples who crave a cozy/romantic night-in.',
            whatsInItForYou: [
                'Unique photobooth experience',
                'Instant printouts',
                'Custom backdrops',
                'High-quality images'
            ],
            whatsIncludedInPackages: [
                'Photobooth setup',
                'On-site technician',
                'Props and accessories',
                'Digital gallery'
            ]
        },
        {
            title: 'Content Creator',
            imageUrl: '/assets/content-creator-recording-shorts-of-wedding-date-saver-co-DMV.webp',
            description: 'For celebrants who see the beauty in unrehearsed shots and want more raw energy.</br>A content creator documents your event like a guest revealing a more authentic side of storytelling.',
            whatsInItForYou: [
                'Unique photobooth experience',
                'Instant printouts',
                'Custom backdrops',
                'High-quality images'
            ],
            whatsIncludedInPackages: [
                'Photobooth setup',
                'On-site technician',
                'Props and accessories',
                'Digital gallery'
            ]
        },
        {
            title: 'VIP Proposal Design',
            imageUrl: '/assets/VIP-proposal-design-man-kneels-down-proposes-to-woman-with-ring-date-saver-co-DMV.webp',
            description: 'Proposals shouldn’t focus on superficial aesthetics. Let us handle the dreamy visuals while you focus on the right words to say before the popping the question.</br></br>We will style the perfect backdrop for your big moment + easy-to-follow tips to make the lead-up to the BIG YES smooth and memorable.</br></br><span class="font-bold">Who is it for?</span></br>For busy romantics who want to wow. ',
            whatsInItForYou: [
                'Unique photobooth experience',
                'Instant printouts',
                'Custom backdrops',
                'High-quality images'
            ],
            whatsIncludedInPackages: [
                'Photobooth setup',
                'On-site technician',
                'Props and accessories',
                'Digital gallery'
            ]
        },
    ];

    return (
        <div id="services" className='bg-[#f4f1e9] pt-20 pb-30'>
            <h2 className="text-2xl sm:text-3xl md:text-4xl text-[#51423e] mt-8 sm:mt-10 font-bold font-the-seasons text-center">
                <span className="text-[#7a6d4e]"> OUR </span> SERVICES
            </h2>
            {/* Underline under "Our Services" */}
            <div className="w-16 sm:w-24 h-[1px] bg-[#8d7467] mx-auto mt-2 opacity-50 mb-24"></div>

            {servicesData.map((service, index) => (
                <ServiceSection
                    key={index}
                    title={service.title}
                    imageUrl={service.imageUrl}
                    description={service.description}
                    whatsInItForYou={service.whatsInItForYou}
                    whatsIncludedInPackages={service.whatsIncludedInPackages}
                    customClasses={index === 1 || index === 3 ? 'bg-[#ccbfb7] !text-[#342b29]' : ''}  // Apply custom Tailwind classes to 
                />
            ))}
        </div>
    );
}

export default Services;
