import React from 'react';

const BestStories = () => {
    return (
        <div className="bg-[#f4f1e9] pt-10 pb-10 md:pt-28 md:min-h-screen relative shadow-sm">
            <div className="max-w-[85%] mx-auto grid grid-cols-1 md:grid-cols-12 gap-6 items-center relative z-10 md:pl-11">
                {/* Left Column: Two Stacked Images */}
                <div className="space-y-2 md:w-[80%] md:col-span-4 z-10 flex flex-col items-center md:items-start">
                    <div className="rounded-lg overflow-hidden shadow-md h-40 md:h-[45%] w-full md:w-auto">
                        <img
                            src="/assets/couple-smiling-admiring-sparkling-wine-sitting-by-low-picnic-table-date-saver-co-picnic-DMV.webp"
                            alt="Couple smiling and admiring sparkling wine"
                            className="object-cover w-full h-full"
                            loading="lazy"
                        />
                    </div>
                    <div className="rounded-lg overflow-hidden shadow-md h-40 md:h-[45%] w-full md:w-auto">
                        <img
                            src="/assets/couple-belly-laughing-on-knit-lounge-chairs-date-saver-co-picnic-DMV.webp"
                            alt="Couple laughing on knitted lounge chairs"
                            className="object-cover w-full h-full"
                            loading="lazy"
                        />
                    </div>
                </div>
                    
                {/* Middle Column: Large Image */}
                <div className="md:relative h-[50%] -mt-[49%] md:mt-0 md:right-28 rounded-lg overflow-hidden md:h-[100%] md:w-[80%] md:col-span-4 md:col-start-5 z-10 flex justify-center w-full">
                    <img
                        src="/assets/couple-smiling-looking-at-INSTAX-photo-while-sitting-on-a-mat-illuminated-by-white-teepee-fabric-datesaverco.webp"
                        alt="Couple sitting down and looking at an INSTAX polaroid"
                        className="object-cover w-full h-full"
                        loading="lazy"
                    />
                </div>

                {/* Right Column: Text Content */}
                <div className="flex flex-col justify-center items-center md:items-start text-center md:right-14 relative md:text-left -mt-40 pb-11 md:mt-0 md:col-span-8 md:col-start-9 z-10">
                    <img
                        src="/assets/brownlogo.png"
                        alt="Brown Date Saver Co logo"
                        className="h-auto w-20 md:w-36 mb-4 md:-translate-x-10"
                        loading="lazy"
                    />
                    <h2 className="text-sm font-the-seasons uppercase text-[#98876d] tracking-wide mb-2">
                        DATE SAVER CO
                    </h2>
                    <p className="text-2xl md:text-5xl font-tt-drugs text-[#51423e] leading-tight">
                        Where the best stories aren't posted—<br /><br />they're lived.
                    </p>
                </div>
                {/* Background Shape */}
                <div className="hidden md:block inset-1 absolute bg-[#ccbfb7] rounded-[75px] w-[30%] h-[100%] left-96 top-24 shadow-lg"></div>
            </div>
        </div>
    );
};

export default BestStories;
