import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';

const createIdFromTitle = (title: string) => {
    return title
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/[^\w-]+/g, ''); // Remove special characters
};

interface ServiceSectionProps {
    title: string;
    imageUrl: string;
    description: string;
    whatsInItForYou: string[];
    whatsIncludedInPackages: string[];
    customClasses?: string;
}

const ServiceSection: React.FC<ServiceSectionProps> = ({
    title,
    imageUrl,
    description,
    whatsInItForYou,
    whatsIncludedInPackages,
    customClasses
}) => {
    const [isInItForYouOpen, setIsInItForYouOpen] = useState(false);
    const [isIncludedInPackagesOpen, setIsIncludedInPackagesOpen] = useState(false);

    // Generate ID from the sanitized title
    const sectionId = createIdFromTitle(title);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div id={sectionId} className="flex flex-col md:flex-row justify-center items-stretch gap-0 pb-32" style={{ scrollMarginTop: '80px' }}>
            {/* Wrapper for Left and Middle Columns */}
            <div className="flex flex-col md:flex-row w-full md:w-2/3 md:h-[350px]">
                {/* Left Column: Photo */}
                <div className="w-full md:w-1/2 flex-shrink-0 overflow-hidden">
                    <img 
                        src={imageUrl} 
                        alt={title} 
                        className="w-full h-full object-cover shadow-xl transition duration-300 ease-in-out transform hover:scale-110" 
                    />
                </div>

                {/* Middle Column: Service Description */}
                <div 
                    className={`w-full md:w-1/2 bg-[#342b29] text-white text-sm p-8 md:rounded-r-[90px] flex flex-col justify-center text-center shadow-2xl ${customClasses}`}
                >
                    <h3 
                        className="text-3xl font-bold font-the-seasons mb-4" 
                        dangerouslySetInnerHTML={{ __html: title }}
                    ></h3>
                    <p 
                        className='font-tt-drugs italic'
                        dangerouslySetInnerHTML={{ __html: description }}
                    ></p>
                    <button 
                        onClick={scrollToTop}
                        className="mt-8 border font-tt-drugs text-sm border-white hover:bg-white hover:text-[#342b29] py-2 px-2 w-38 mx-auto"
                    >
                        SAVE MY DATE
                    </button>
                </div>
            </div>

            {/* Right Column: Dropdown Descriptions */}
            <div className="w-full md:w-1/3 text-left text-[#51423e] p-6 overflow-auto">
                <div className="mb-4">
                    <h4 
                        className="text-xl font-bold cursor-pointer flex items-center font-the-seasons" 
                        onClick={() => setIsInItForYouOpen(!isInItForYouOpen)}
                    >
                        What’s in it for you: <FiChevronDown className="ml-2" />
                    </h4>
                    <div 
                        className={`overflow-hidden transition-all duration-500 ${isInItForYouOpen ? 'max-h-screen' : 'max-h-0'}`}
                    >
                        <ul className="list-disc ml-5 mt-2 space-y-2 font-tt-drugs">
                            {whatsInItForYou.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div>
                    <h4 
                        className="text-xl font-bold font-the-seasons cursor-pointer flex items-center" 
                        onClick={() => setIsIncludedInPackagesOpen(!isIncludedInPackagesOpen)}
                    >
                        What’s included in our packages: <FiChevronDown className="ml-2" />
                    </h4>
                    <div 
                        className={`overflow-hidden transition-all duration-500 ${isIncludedInPackagesOpen ? 'max-h-screen' : 'max-h-0'}`}
                    >
                        <ul className="list-disc ml-5 mt-2 space-y-2 font-tt-drugs">
                            {whatsIncludedInPackages.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServiceSection;
