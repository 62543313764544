import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';  // Import the App component

// Render the App component into the #root element in index.html
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')  // This references the div with id="root" in index.html
);
