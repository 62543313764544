import React from 'react';

const WhatWeDo = () => {
    return (
        <div className="bg-[#f4f1e9] w-full h-full pt-8 pb-10 overflow-x-hidden">
            <div className="relative w-full mx-auto">
                {/* Background Shape */}
                <div className="bg-[#e1d9d2] w-[75%] sm:w-[80%] h-48 sm:h-64 flex rounded-r-[30px] sm:rounded-r-[50px] z-0 shadow-lg"></div>
                
                {/* Image */}
                <div className="flex justify-end -mt-36 sm:-mt-48 z-10">
                    <img 
                        src="/assets/whatwedo.jpg" 
                        alt="What We Do" 
                        className="w-[90%] sm:w-[70%] h-48 sm:h-64 md:h-80 object-cover rounded-l-xl shadow-lg"
                        loading="lazy"
                    />
                </div>
            </div>

            <h2 className="text-2xl sm:text-3xl md:text-4xl text-[#51423e] mt-12 pt-12 sm:mt-10 font-bold font-the-seasons text-center">
                <span className="text-[#7a6d4e]"> WHAT </span> WE DO
            </h2>
            <div className="w-16 sm:w-24 h-[1px] bg-[#8d7467] mx-auto mt-2 opacity-50"></div>

            {/* 3 Columns Section */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8 sm:mt-12 max-w-6xl mx-auto text-center font-tt-drugs text-[#342b29] px-4">
                {/* Column 1 */}
                <div className="flex flex-col items-center">
                    <div className="relative w-full h-48 sm:h-56 md:h-64 overflow-hidden mb-4 shadow-lg">
                        <img
                            src="/assets/whatwedo1.webp"
                            alt="WE PREPARE"
                            className="w-full h-full object-cover transition duration-300 ease-in-out transform hover:scale-110"
                            loading="lazy"
                        />
                    </div>
                    <h3 className="text-xl sm:text-2xl font-semibold">WE PREPARE</h3>
                    <p className="mt-2 text-sm sm:text-base">
                        We handle all the setup, whether it's a picnic, photobooth, or capturing content, so you can focus on being present.<br /><br />
                        NO SOCIAL MEDIA STRESS HERE.
                    </p>
                </div>

                {/* Column 2 */}
                <div className="flex flex-col items-center">
                    <div className="relative w-full h-48 sm:h-56 md:h-64 overflow-hidden mb-4 shadow-lg">
                        <img
                            src="/assets/whatwedo2.webp"
                            alt="YOU ENJOY"
                            className="w-full h-full object-cover transition duration-300 ease-in-out transform hover:scale-110"
                            loading="lazy"
                        />
                    </div>
                    <h3 className="text-xl sm:text-2xl font-semibold">YOU ENJOY</h3>
                    <p className="mt-2 text-sm sm:text-base">
                        We leave the rest to you—the laughter, the connections so you won’t have to be that person pulling out a phone or worrying about the setup.
                    </p>
                </div>

                {/* Column 3 */}
                <div className="flex flex-col items-center">
                    <div className="relative w-full h-48 sm:h-56 md:h-64 overflow-hidden mb-4 shadow-lg">
                        <img
                            src="/assets/whatwedo3.webp"
                            alt="WE WRAP UP"
                            className="w-full h-full object-cover transition duration-300 ease-in-out transform hover:scale-110"
                            loading="lazy"
                        />
                    </div>
                    <h3 className="text-xl sm:text-2xl font-semibold">WE WRAP UP</h3>
                    <p className="mt-2 text-sm sm:text-base">
                        After the fun, we handle the cleanup, send you the footage, leaving you with nothing but great memories—the laughter, the connections that aren’t staged but felt deeply, long after the day is done.
                    </p>
                </div>
            </div>
            
            {/* Centered Button */}
            <div className="flex justify-center mt-16">
                <button className="font-tt-drugs underline text-[#342b29] text-xs sm:text-sm border border-[#342b29] px-6 sm:px-9 py-3 hover:bg-[#342b29] hover:text-white transition border-solid">
                    SAVE MY DATE
                </button>
            </div>
        </div>
    );
}

export default WhatWeDo;
