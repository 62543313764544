import React, { useState, FormEvent } from "react";

const subscribeForm = "https://dashboard.mailerlite.com/forms/1020808/126536398884832508/share";

const findRequestURL = (mail: string): string => {
  const formURL = subscribeForm.split("/");
  const getNumbers = formURL.filter((item) => Number(item) && item);

  const accountID = getNumbers[0];
  const formID = getNumbers[1];

  const requestURL = `https://assets.mailerlite.com/jsonp/${accountID}/forms/${formID}/subscribe?fields[email]=${mail}`;

  return requestURL;
};

interface HeroProps {
  heading: string;
  message: string;
}

const Hero: React.FC<HeroProps> = ({ heading, message }) => {
  const [mail, setMail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [apiMessage, setApiMessage] = useState<string>("");

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    fetch(findRequestURL(mail), {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setApiMessage("Thanks for subscribing!");
        } else {
          setApiMessage("Something went wrong, please try again.");
        }
      })
      .catch(() => setApiMessage("Something went wrong, please try again."))
      .finally(() => {
        setLoading(false);
        setMail("");
      });
  };

  return (
    <div className="flex items-center justify-center min-h-screen relative">
      <img
        src="/assets/hero-picnic-woide-angle-datesaverco-DMV.webp"
        alt="Background"
        className="absolute top-0 left-0 w-full h-full object-cover z-0 "
      />
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-40 z-10" />
      <div className="flex flex-col items-center justify-center p-5 text-center text-white relative z-20 max-w-full pt-24"> {/* Added pt-24 for top padding */}
        <p className="text-white text-xl sm:text-2xl font-the-seasons">COMING SOON</p>
        <div className="w-24 h-[1px] bg-white mx-auto mb-6 opacity-50"></div>
        <div className="max-w-full sm:max-w-[500px] md:max-w-[1000px] mx-auto mb-4">
          <img
            src="/assets/cream-hero-logo.png"
            alt="Hero Logo"
            className="w-full h-auto object-contain"
          />
        </div>
        <p className="font-the-seasons font-bold text-lg sm:text-4xl tracking-wide text-white">
          Memories Without Trying <br />
        </p>
        <p className="font-tt-drugs text-xs sm:text-lg">
          DC <span className="mx-3 sm:mx-6" style={{ fontFamily: "Arial" }}>|</span>
          MD <span className="mx-3 sm:mx-6" style={{ fontFamily: "Arial" }}>|</span>
          NOVA
        </p>
        <form onSubmit={onSubmit} className="mt-4 pt-12 sm:pt-24 relative flex flex-col items-center">
          <input
            type="email"
            value={mail}
            style={{ width: `${Math.max(mail.length + 1, 40)}ch` }}
            onChange={(e) => setMail(e.target.value)}
            placeholder="Enter Your Email"
            className={`font-tt-drugs text-sm rounded-full px-5 sm:px-7 py-2 mb-3 text-white bg-transparent border border-white placeholder-white text-center w-[100%] sm:w-auto ${
              mail ? "bg-gray-100 bg-opacity-50" : ""
            }`}
            required
          />
          <p className="font-tt-drugs text-white text-xs sm:text-lg mb-4 px-2">
            Priority booking for the first 10 sign-ups! Grab early-bird deals and be entered for a FREE picnic.
          </p>

          <button
            type="submit"
            className="font-tt-drugs px-4 py-2 border border-[#f1f1ef] bg-transparent text-white text-lg hover:bg-white hover:text-black transition border-solid w-[80%] sm:w-auto tracking-widest" 
            disabled={loading}
          >
            {loading ? "Loading..." : "SAVE MY DATE"}
          </button>
        </form>
        <p className="font-tt-drugs text-white text-md sm:text-lg mt-16 mb-0 px-2">
            Send us a message below if you have a specific service in mind and would like to secure a date before our opening.
          </p>
        {apiMessage && <p className="mt-2 text-white text-sm">{apiMessage}</p>}
      </div>
      
    </div>
  );
};

export default Hero;
