import React, { useState } from 'react';
import { FaInstagram, FaFacebookF, FaYoutube, FaTiktok } from 'react-icons/fa';
import { AiOutlineMail } from "react-icons/ai";

interface FormData {
    name: string;
    email: string;
    message: string;
}

const ContactPage: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        message: ''
    });

    const [copied, setCopied] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [messageContent, setMessageContent] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Programmatically submit the form to the hidden iframe
        const formElement = document.getElementById('googleForm') as HTMLFormElement;
        formElement.submit();

        // Reset form after submission
        setFormData({
            name: '',
            email: '',
            message: ''
        });

        // Show success message
        setMessageContent('Your message has been sent successfully.');
        setIsError(false);
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 3000);
    };

    const handleCopyEmail = () => {
        navigator.clipboard.writeText("datesaverco@gmail.com")
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000); // Hide the notification after 2 seconds
            })
            .catch(err => console.error('Could not copy text: ', err));
    };

    return (
        <div id="contact" className="bg-[#231f20] py-10 px-0">
            {/* Top Section: Image with Background Shape */}
            <div className="relative flex justify-center items-center mb-32">
                <div className="absolute inset-y-0 left-0 w-[90%] shadow-lg  bg-[#5b4e4a] rounded-r-[40px] lg:rounded-r-[60px] hidden lg:block"></div>
                <img
                    src="/assets/we-are-listening-girl-sets-up-pillows-for-picnic-by-datesaverco-naturebackground-low-picnic-table-behind.webp"
                    alt="We Are Listening"
                    className="relative z-10 rounded-lg shadow-lg w-auto h-48 md:h-72 top-11"
                />
            </div>

            {/* Bottom Section: Two Columns */}
            <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-5 gap-16 px-4 lg:px-0">
                {/* Left Column: Text and Form */}
                <div className="text-white space-y-8 text-left col-span-3 lg:max-w-[80%]">
                    <h2 className="text-lg md:text-xl font-bold font-the-seasons">
                        We know that real connections happen when the cameras are off.
                    </h2>
                    <p className="font-tt-drugs text-sm">
                        We're excited to hear from you! Do you want to book and inquire about a specific date?<br /><br />
                        What would make our future experiences better suit your needs?<br /><br />
                        Share below and we will work with you to shape something special!
                    </p>

                    {/* Contact Form */}
                    <form className="space-y-4 font-tt-drugs max-w-[80%]" onSubmit={handleSubmit}>
                        <div>
                            <label className="block text-sm font-semibold">name:</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="w-full p-2 bg-[#51423e] text-white border-b border-white"
                                placeholder="required*"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-semibold">email:</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full p-2 bg-[#51423e] text-white border-b border-white"
                                placeholder="required*"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-semibold">message:</label>
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                className="w-full p-2 bg-[#51423e] text-white border-b border-white h-32"
                                placeholder="required*"
                                required
                            ></textarea>
                        </div>
                        <div className="flex items-center space-x-4">
                            <button
                                type="submit"
                                className="bg-[#342b29] text-[#f4f1e9] border px-8 py-1 font-semibold font-the-seasons hover:bg-white hover:text-black"
                            >
                                Send
                            </button>
                            {showMessage && (
                                <p className={`text-sm font-the-seasons font-semibold ${isError ? 'text-red-500' : 'text-green-500'}`}>
                                    {messageContent}
                                </p>
                            )}
                        </div>
                    </form>

                    {/* Hidden Google Form */}
                    <form id="googleForm" action="https://docs.google.com/forms/u/0/d/e/1FAIpQLScIc4aHlKHjnQrKsSdF3gOnDOhYi4gta0GwQNufL2Q6qK8X-g/formResponse" method="POST" target="hidden_iframe">
                        <input type="hidden" name="entry.2005620554" value={formData.name} />
                        <input type="hidden" name="entry.1045781291" value={formData.email} />
                        <input type="hidden" name="entry.839337160" value={formData.message} />
                    </form>
                    {/* Hidden iframe for form submission */}
                    <iframe name="hidden_iframe" style={{ display: 'none' }}></iframe>
                </div>

                {/* Right Column: Community and Business Info */}
                <div className="text-white space-y-8 text-left col-span-2 lg:pl-14">
                    {/* Social Media Icons */}
                    <div className="flex space-x-4 justify-start lg:justify-start">
                        <a href="https://instagram.com/datesaverco" target="_blank" rel="noopener noreferrer">
                            <FaInstagram className="w-6 h-6 text-white hover:text-[#ddc6a3]" />
                        </a>
                        <a href="https://facebook.com/profile.php?id=61561750892261" target="_blank" rel="noopener noreferrer">
                            <FaFacebookF className="w-6 h-6 text-white hover:text-[#ddc6a3]" />
                        </a>
                        <a href="https://www.tiktok.com%2F%40datesaverco%3Fis_from_webapp%3D1%26sender_device%3Dpc" target="_blank" rel="noopener noreferrer">
                            <FaTiktok className="w-6 h-6 text-white hover:text-[#ddc6a3]" />
                        </a>
                        <a href="https://youtube.com/%40datesaverco?si=sD66jorEstXtFCIR" target="_blank" rel="noopener noreferrer">
                            <FaYoutube className="w-6 h-6 text-white hover:text-[#ddc6a3]" />
                        </a>
                        <button onClick={handleCopyEmail} className="focus:outline-none">
                            <AiOutlineMail className="w-6 h-6 text-white hover:text-[#ddc6a3]" />
                        </button>
                    </div>
                    {copied && (
                        <p className="text-[#ddc6a3] text-sm font-the-seasons font-semibold tracking-wider text-left lg:text-left">Email Copied to Clipboard</p>
                    )}

                    <div className="space-y-2 font-tt-drugs text-left lg:text-left">
                        <h3 className="text-lg font-semibold font-the-seasons">Join our community</h3>
                        <p>DC, Maryland, and Northern Virginia</p>
                        <p>datesaverco@gmail.com</p>
                        <p>(240) 716-2616</p>
                    </div>

                    <div className="space-y-2 font-tt-drugs text-left lg:text-left">
                        <h3 className="text-lg font-semibold font-the-seasons">Business Hours</h3>
                        <p>Monday – Friday: 9:00am – 12:00am</p>
                        <p>Saturday – Sunday: 9:00am – 9:00pm</p>
                    </div>

                    <div className="space-y-2 text-left lg:text-left">
                        <h3 className="text-lg font-semibold font-the-seasons">Website Credits:</h3>
                        <p className="font-tt-drugs">Developer: Ted Liu</p>
                        <p className="font-tt-drugs">Photography: <a href="https://instagram.com/nvu.films" className="text-[#ddc6a3]">@NVU.Films</a>, <a href="https://instagram.com/thedmveater" className="text-[#ddc6a3]">@theDMVEater</a>, Frances Liu</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactPage;
