import React from 'react';
import { GiCheckMark } from "react-icons/gi";

const FYP = () => {
  return (
    <div className='bg-[#f4f1e9]'>
      {/* Hero Section */}
      <div className="relative h-[250px] md:h-[500px]">
        <img
          src="assets/FYP-subpage-banner-sunflower-arrangement-on-picnic-table-datesaverco-dmv.webp"
          alt="For You Picnic"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-40 flex w-full">
          <h1 className="text-white font-the-seasons text-sm md:text-4xl md:text-left text-center flex items-end pl-5 pb-24 md:items-center md:pl-16 md:pt-44">
            For You Picnic<span className='font-sans font-thin'>&nbsp;-&nbsp;</span>escape the screen, embrace the scene.
          </h1>
        </div>
      </div>

      {/* What is FYP Section */}
      <div className="py-10 px-6 md:py-16 md:px-28 text-left">
        <h2 className="text-xl md:text-4xl font-the-seasons mb-6 text-[#231f20]">What is FYP?</h2>
        <p className="text-md md:text-xl text-[#231f20] font-tt-drugs tracking-wider font-thin pl-2 md:pl-11">
          Our For You Picnic (FYP) is a beautifully crafted picnic experience without the hassle. We believe
          that disconnecting is where you can truly connect and the FYP is perfect for those seeking just
          that – an enjoyable outing with minimal effort.
        </p>
      </div>

      {/* Get Started Section */}
      <div className="py-10 px-4 md:py-16 md:px-32 text-left">
        <h2 className="text-xl md:text-4xl text-[#231f20] font-the-seasons mb-8 md:mb-12 text-center">Get Started</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* Step 1 */}
          <div className='text-[#231f20]'>
            <h3 className="text-lg md:text-2xl font-semibold font-the-seasons mb-4">Step 1 <span className='font-sans font-extralight'>|</span> Book</h3>
            <p className="text-md md:text-lg font-tt-drugs leading-relaxed">
              Choose your picnic date and experience through our booking system! Then finalize everything by signing the Date Saver Co. contract we’ll send you afterward. This will lock in your special day and ensure everything is set.
            </p>
          </div>
          {/* Step 2 */}
          <div className='text-[#231f20]'>
            <h3 className="text-lg md:text-2xl font-bold font-the-seasons mb-4">Step 2 <span className='font-sans font-extralight'>|</span> Pick a Design</h3>
            <p className="text-md md:text-lg font-tt-drugs leading-relaxed">
              You’ll receive a confirmation email where we’ll ask you to select a theme from our curated options. We know your schedule is busy, so our pre-planned themes are designed with ease in mind. If you have specific ideas or inspiration, we’ll do our best to bring them to life!
            </p>
          </div>
          {/* Step 3 */}
          <div className='text-[#231f20]'>
            <h3 className="text-lg md:text-2xl font-bold font-the-seasons mb-4">Step 3 <span className='font-sans font-extralight'>|</span> Enjoy</h3>
            <p className="text-md md:text-lg font-tt-drugs leading-relaxed">
              We’ll bring your vision to life, setting up the perfect picnic before you arrive, so you can dive straight into making memories. Once the picnic is over, we’ll quietly gather our things, leaving you with moments that will stay with you long after the picnic ends.
            </p>
          </div>
        </div>
      </div>

      {/* What's Included Section */}
      <div className="py-10 px-4 md:py-16 md:px-20 text-left font-thin">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
          {/* List Section */}
          <div className="text-[#231f20]">
            <h3 className="text-lg md:text-2xl font-bold font-the-seasons mb-4 tracking-wider">What<span className='font-sans font-thin'>'</span>s Included:</h3>
            <ul className="pl-5 md:pl-14 text-md md:text-lg font-tt-drugs leading-relaxed">
              <li className="flex items-center mb-2">
                <GiCheckMark className='mr-3 md:mr-5 text-md md:text-sm'/> Picnic Table and Table Scape
              </li>
              <li className="flex items-center mb-2">
                <GiCheckMark className='mr-3 md:mr-5 text-md md:text-sm'/> Personalized Place Cards for Each Guest (Glass Arch Frames)
              </li>
              <li className="flex items-center mb-2">
                <GiCheckMark className='mr-3 md:mr-5 text-md md:text-sm'/> Pillows and Water-Proof Mats
              </li>
              <li className="flex items-center mb-2">
                <GiCheckMark className='mr-3 md:mr-5 text-md md:text-sm'/> Seasonal Fresh Flowers
              </li>
              <li className="flex items-center mb-2">
                <GiCheckMark className='mr-3 md:mr-5 text-md md:text-sm'/> NA Sparkling Wine and Voss Bottled Water
              </li>
              <li className="flex items-center mb-2">
                <GiCheckMark className='mr-3 md:mr-5 text-md md:text-sm'/> Throw Blanket
              </li>
              <li className="flex items-center mb-2">
                <GiCheckMark className='mr-3 md:mr-5 text-md md:text-sm'/> Boho Umbrella**
              </li>
              <li className="flex items-center mb-2">
                <GiCheckMark className='mr-3 md:mr-5 text-md md:text-sm'/> Knit Lounge Chairs
              </li>
              <li className="flex items-center mb-2">
                <GiCheckMark className='mr-3 md:mr-5 text-md md:text-sm'/> Conversation Starter Cards
              </li>
              <li className="flex items-center mb-2">
                <GiCheckMark className='mr-3 md:mr-5 text-md md:text-sm'/> Hygiene (wipes, hand sanitizer, paper towels)
              </li>
              <li className="flex items-center mb-2">
                <GiCheckMark className='mr-3 md:mr-5 text-md md:text-sm'/> Electric Handheld Fans
              </li>
              <li className="flex items-center mb-2">
                <GiCheckMark className='mr-3 md:mr-5 text-md md:text-sm'/> Bluetooth Speaker
              </li>
            </ul>
            <p className="text-md md:text-lg font-tt-drugs leading-relaxed mt-2">
              BONUS &nbsp;&nbsp; INSTAX Pre-loaded with 10 Prints<br />
              BONUS &nbsp;&nbsp; Date Saver Co Curated Playlist<br />
              BONUS &nbsp;&nbsp; FREE Homemade Date Saver Co Exclusive Candle | Volcano Scented <br />&nbsp;&nbsp;&nbsp;&nbsp;(Not Available Anywhere Else)
            </p>
            <p className="text-sm italic text-[#342b29] opacity-85 mt-4">
              *Choices for add-ons are available through the booking process
            </p>
          </div>

          {/* Image and Title Section */}
          <div className="flex flex-col items-start -ml-1 md:-ml-20 mr-4 md:mr-16">
            <h1 className='font-the-seasons font-bold text-[#342b29] text-2xl md:text-3xl w-full text-left mb-5'>For You Picnic:</h1>
            <img
              src="assets/FYP-whats-included-couple-eating-at-picnic-table-datesaverco-DMV.webp" 
              alt="For You Picnic Included Items"
              className="w-full h-auto object-cover shadow-xl"
            />
          </div>
        </div>
      </div>

      {/* Acuity Scheduling Section */}
      <div className="py-10 px-4 md:py-16 md:px-20 text-left font-thin">
        <h2 className="text-xl md:text-4xl text-[#231f20] font-the-seasons mb-8 md:mb-12 text-center">Schedule and Checkout</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
          {/* Text and Image Section */}
          <div className="text-[#231f20]">
            <h3 className="text-lg md:text-2xl font-bold font-the-seasons mb-4">Schedule and Checkout</h3>
            <p className="text-md md:text-lg font-tt-drugs leading-relaxed">
              Using our booking system, you can reserve your picnic date and location! After you submit, we will reach out to you for design preferences or you can choose to let us handle the details!<br/><br/>
              Please reach out to us by phone, email or the form below if you have any questions before booking.
            </p>
            <img
              src="assets/FYP-schedule-instax-photos-on-vintage-luggage-datesaverco-dmv.webp"
              alt="Instax and Scheduling"
              className="w-full h-auto object-cover mt-6"
            />
          </div>
        
            {/* Acuity Scheduling iframe */}
            <div className="p-4 w-full h-[1600px] md:h-[1400px]">
                <iframe 
                    src="https://app.acuityscheduling.com/schedule.php?owner=33318829&owner=33318829&appointmentType=category:Picnic" 
                    title="Schedule Appointment" 
                    width="100%" 
                    height="100%"
                    className="w-full h-full overflow-hidden">
                </iframe>
            </div>
        </div>
      </div>
    </div>
  );
};

export default FYP;
