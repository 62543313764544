import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Optional: for setting the page title and meta tags
import Hero from './components/Hero/Hero';
import Contact from './components/Contact/Contact';
import WhoWeAre from './components/WhoWeAre/WhoWeAre';
import Moments from './components/Moments/Moments';
import WhatWeDo from './components/WhatWeDo/WhatWeDo';
import BestStories from './components/BestStories/BestStories';
import NavBar from './components/NavigationBar/NavBar';
import Services from './components/Services/Services';
import FAQ from './components/FAQ/FAQ';
import FYP from './components/FYP/FYP'; 
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import './styles/globals.css';

const App: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Date Saver Co</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <Router>
        <NavBar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero heading="date saver co" message="Join the waitlist to receive an early-bird discount code before we launch!" />
                <Moments />
                <WhoWeAre />
                <WhatWeDo />
                <Services />
                <BestStories />
                <FAQ />
              </>
            }
          />
          <Route path="/fyp" element={<FYP />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="*" element={<h1>404 - Page Not Found</h1>} />
        </Routes>
        <Contact /> {/* Contact appears on all pages */}
      </Router>
    </>
  );
};

export default App;
