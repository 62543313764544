import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="bg-[#f4f1e9] min-h-screen py-32 px-6 md:px-28 text-left">
      <h1 className="text-3xl md:text-5xl font-the-seasons mb-8 text-[#231f20] text-center">
        Terms and Conditions
      </h1>

      <div className="max-w-5xl mx-auto text-[#231f20] font-tt-drugs text-lg md:text-xl tracking-wide leading-relaxed">
        <section className="mb-12">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">1. Introduction</h2>
          <p>
            Welcome to Date Saver Co. These terms and conditions outline the rules and regulations for the use of our website and services.
            By accessing this website and using our services, you agree to comply with and be bound by the following terms and conditions.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">2. Intellectual Property Rights</h2>
          <p>
            All content, including text, images, and media on this site, is owned by Date Saver Co. and is protected by intellectual property laws.
            You are not permitted to republish, sell, or redistribute our content without explicit permission.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">3. Use of Service</h2>
          <p>
            Our services are intended for personal, non-commercial use only. Any misuse of our booking system, site, or offerings may result
            in the termination of your access to our services.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">4. Cancellations and Refunds</h2>
          <p>
            You may cancel or modify your booking up to 48 hours before the scheduled event. Refunds will be issued for cancellations made
            within the specified period. Please refer to our cancellation policy for further details.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">5. Limitation of Liability</h2>
          <p>
            Date Saver Co. shall not be held liable for any damages, including but not limited to direct, indirect, incidental, or consequential damages,
            arising from your use of our website or services.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">6. Privacy Policy</h2>
          <p>
            We are committed to protecting your privacy. Any personal information you provide to us through the website will be used in accordance with
            our <a href="/privacy-policy" className="text-[#342b29] underline">Privacy Policy</a>.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">7. Governing Law</h2>
          <p>
            These terms and conditions are governed by and construed in accordance with the laws of [Your Country/State] and you irrevocably
            submit to the exclusive jurisdiction of the courts in that state or location.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">8. Changes to Terms</h2>
          <p>
            We reserve the right to update these terms and conditions at any time. Your continued use of the site after any changes are made
            will constitute your acceptance of the updated terms.
          </p>
        </section>

        <p className="text-md italic mt-8">Last Updated: [Date]</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
